import {
  Fragment,
  useCallback,
  useContext,
  useState,
  useRef,
  useEffect,
} from "react";
import {
  Badge,
  Button,
  Card,
  FormControl,
  Image,
  InputGroup,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { BsHeart, BsHeartFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import slugify from "slugify";
import { ProductCardSkeleton } from "./loaders";
import { Wrapper } from ".";
import CartContext from "../../context/Cart";
import FavsContext from "../../context/Favs";
import PricesContext from "../../context/Prices";
import {
  ROUTES,
  SECTION_PERMISSIONS,
  ADD_PRODUCT_TYPE,
  SHOW_NOTIFICATION_ADD_TO_CART,
  /* PRICE_OPTIONS, */
} from "../../global";
import { formatDate } from "../../helpers";
import useProduct from "../../hooks/useProduct";
import useAuth from "../../hooks/useAuth";

import promoCantidades from "../../assets/img/promos/promo-cantidades.svg";
import emptyImage from "../../assets/img/empty-image.png";
import "./styles/ProductCard.scss";

import Overlay from "react-bootstrap/Overlay";

export const ProductCard = ({ productName, outletProduct, tipo }) => {
  const { canAccess } = useAuth();
  const { addToCart } = useContext(CartContext);
  const [isLoading, setIsLoading] = useState(true);
  const { favsReduced, addToFavs, removeFromFavs } = useContext(FavsContext);
  const { price } = useContext(PricesContext);
  const [units, setUnits] = useState("");
  const { t, i18n } = useTranslation();

  const [show, setShow] = useState(false);
  const target = useRef(null);

  const product = useProduct(productName, {
    ...(price && { precio: price }),
    stock: true,
    arbol: true,
    files: true,
    estado: true,
  });

  /* const productPricePVR = useProduct(productName, {
    precio: PRICE_OPTIONS.pvr.value,
  }); */

  /**
   * @description Calcula el precio alternativo de un producto (Si fuese outlet lo carga del valor recogido)
   * @returns precio alternativo
   */
  const getAlterPrice = useCallback(() => {
    return parseFloat(
      outletProduct
        ? outletProduct.PrecioOutlet
          ? outletProduct.PrecioOutlet
          : product.PrecioFinDeSerie
        : product.PrecioFinDeSerie,
    );
  }, [product, outletProduct]);

  /**
   * @description Carga el badge que informa si hay Promociones del producto.
   *
   */

  const PromosBadge = useCallback(() => {
    let bg = "";
    let text = "";

    if (product.Promociones.length > 0 && !outletProduct) {
      bg = "danger";
      text = t("en_promocion");
    }

    return (
      <Badge bg={bg} className="rounded-0 text-uppercase align-middle">
        {text}
      </Badge>
    );
  }, [product, outletProduct, t]);

  /**
   * @description Carga el badge que informa del Stock del producto.
   */
  const StockBadge = useCallback(() => {
    let bg = "";
    let text = "";

    if (product.Caducado === true && product.Stock > 21) {
      bg = "";
      text =
        product.Stock < 21 && product.Stock > 0
          ? t("solo_quedan_X_unidades", {
              stock: product.Stock,
              count: product.Stock,
            })
          : "";
    }

    if (
      outletProduct !== undefined &&
      outletProduct.Cantidad > 21 &&
      product.Stock > 21
    ) {
      bg = "";
      text =
        product.Stock < 21 && product.Stock > 0
          ? t("solo_quedan_X_unidades", {
              stock: product.Stock,
              count: product.Stock,
            })
          : "";
    }

    if (
      (outletProduct !== undefined &&
        outletProduct.Cantidad < 21 &&
        outletProduct.Cantidad > 0) ||
      (product.Stock < 21 && product.Stock > 0)
    ) {
      bg = "warning";
      text =
        product.Stock < 21 && product.Stock > 0
          ? t("solo_quedan_X_unidades", {
              stock: product.Stock,
              count: product.Stock,
            })
          : "";
    } else {
      if (product.StockEstado === 2) {
        bg = "warning";
        text =
          product.Stock < 21 && product.Stock > 0
            ? t("solo_quedan_X_unidades", {
                stock: product.Stock,
                count: product.Stock,
              })
            : "";
      }

      if (
        (outletProduct !== undefined && outletProduct.Cantidad < 1) ||
        (product.StockEstado === 3 && product.Stock < 1)
      ) {
        bg = "danger";
        text =
          product.FechaProxLlegada &&
          product.FechaProxLlegada !== "0000-00-00" &&
          !product.Caducado &&
          !product.FinDeSerie
            ? t("fecha_prox_llegada_X", {
                date: formatDate(
                  product.FechaProxLlegada,
                  "dd.MM.yy",
                  i18n.resolvedLanguage,
                ),
              })
            : "";
      }
    }

    return (
      <Badge bg={bg} className="rounded-0 text-uppercase align-middle">
        {text}
      </Badge>
    );
  }, [product, i18n.resolvedLanguage, t, outletProduct]);

  useEffect(() => {
    if (product && product.Nombre) {
      setIsLoading(false);
    }
  }, [product]);

  /**
   * @description Carga el badge que informa del Stock del producto.
   */
  const StockInfo = useCallback(() => {
    let variant = "success";
    let text = t("stock_inmediato");

    product.Stock =
      product.Stock === null || isNaN(product.Stock) ? 0 : product.Stock;
    product.Stock = parseInt(
      outletProduct
        ? outletProduct.Cantidad
          ? outletProduct.Cantidad
          : product.Stock
        : product.Stock,
    );

    if (product.StockEstado === 2) {
      variant = "warning";
      text = t("stock_bajo");
    }

    if (
      (outletProduct !== undefined && outletProduct.Cantidad < 1) ||
      (product.StockEstado === 3 && product.Stock < 1)
    ) {
      variant = "danger";
      text = t("sin_stock");
    }

    if (product.StockEstado === 4) {
      variant = "info";
      text = t("bajo_pedido");
    }

    return (
      <div
        className={`text-${variant} text-uppercase float-end d-flex text-1 justify-content-center align-items-center gap-1 align-self-end ms-auto`}
        style={{}}
      >
        <span
          className={`bg-${variant} rounded-circle`}
          style={{ width: "12px", height: "12px", display: "inline-block" }}
        >
          &nbsp;
        </span>{" "}
        {text}
      </div>
    );
  }, [product, outletProduct, t]);

  product.Stock = parseInt(product.Stock);

  const handleUnits = (event, selectedUnits) => {
    event.stopPropagation();

    if (!selectedUnits) {
      setUnits("");

      return;
    }

    const unitsAsNumber = parseInt(selectedUnits);

    unitsAsNumber < 0
      ? setUnits("")
      : unitsAsNumber > product.Stock &&
          (product.Caducado || outletProduct !== undefined)
        ? setUnits(product.Stock)
        : setUnits(unitsAsNumber);
  };

  const handleAddToCart = (event) => {
    event.stopPropagation();

    addToCart(
      product.Nombre,
      units,
      SHOW_NOTIFICATION_ADD_TO_CART.yes,
      ADD_PRODUCT_TYPE.add,
      outletProduct ? outletProduct.TipoOutlet : "",
    );
    setUnits("");
  };

  const handleAddToFavs = (event) => {
    event.stopPropagation();
    event.preventDefault();

    addToFavs(product.Nombre);
  };

  const handleRemoveFromFavs = (event) => {
    event.stopPropagation();
    event.preventDefault();

    removeFromFavs(product.Nombre);
  };

  return (
    <Wrapper
      Component={Card}
      className="components-ui-product-card rounded-0 h-100"
      data-testid="product-card"
    >
      {isLoading ? (
        <ProductCardSkeleton />
      ) : (
        <>
          <Card.Body
            as={Link}
            to={`/${ROUTES[i18n.resolvedLanguage].products}/${
              slugify(product.Nombre, { lower: true }) +
              (outletProduct && outletProduct.TipoOutlet ? "#outlet" : "")
            }`}
            className="p-0 d-flex flex-column"
          >
            <div className="image-frame border border-brand-gray-light">
              <div className="image-frame-wrapper">
                <div className="image-frame-badges-wrapper">
                  <div>
                    <StockBadge />
                  </div>
                  <div>
                    <PromosBadge />
                  </div>
                </div>
                <Image
                  src={product.ImagenWeb_medium ?? emptyImage}
                  alt={product.Nombre}
                  className="d-block mx-auto"
                  loading="lazy"
                  decoding="async"
                  fluid
                />
              </div>
            </div>
            <div className="bg-brand-gray-light p-3 flex-grow-1">
              <small className="ecommerce-sidebar-link text-brand-gray-dark text-color-hover-primary">
                {product.Arbol.Familia.Nombre},{" "}
                {product.Arbol.SubFamilia.Nombre}
              </small>
              <h4 className="text-4 line-height-2 ecommerce-sidebar-link text-primary fw-bold m-0">
                {product.Nombre}
                {favsReduced.includes(product.Nombre) ? (
                  <span
                    className="text-brand-primary float-end px-2"
                    role="button"
                    onClick={handleRemoveFromFavs}
                  >
                    <BsHeartFill />
                  </span>
                ) : (
                  <span
                    className="text-brand-gray-dark float-end px-2"
                    role="button"
                    onClick={handleAddToFavs}
                  >
                    <BsHeart />
                  </span>
                )}
              </h4>
              <div className="d-flex flex-row gap-2 flex-wrap my-1">
                {[...new Set(Object.values(product.Tags ?? {}))]
                  .filter((tag) => tag)
                  .map((tag) => {
                    return (
                      <small key={slugify(tag)} className="d-inline-block">
                        {tag}
                      </small>
                    );
                  })}
                <small className="d-inline-block"></small>
              </div>
              <small className="text-brand-gray-dark m-0">
                {product.DescripcionListados}
              </small>
            </div>
          </Card.Body>
          <Card.Footer className="p-0 border-0">
            <div className="d-flex align-items-center gap-2 mt-3">
              {(price && product.Precios.Neto.Importe > 0) ||
              product.PrecioFinDeSerie > 0 ? (
                <Fragment>
                  <Badge
                    bg="brand-gray"
                    className="badge-ecommerce d-block my-0 text-uppercase"
                  >
                    {t("neto")}
                  </Badge>
                </Fragment>
              ) : (
                <Fragment>
                  <Badge
                    bg="danger"
                    className="badge-ecommerce d-block my-0 text-uppercase"
                  >
                    {t("precio_a_consultar")}
                  </Badge>
                </Fragment>
              )}
              <StockInfo />
            </div>
            <div className="d-flex align-items-center gap-2">
              {price && getAlterPrice() > 0 ? (
                <div className="mt-2">
                  <span className="fw-bold d-inline-block h4-listado my-0 text-dark">
                    {new Intl.NumberFormat(i18n.resolvedLanguage, {
                      style: "currency",
                      currency: "EUR",
                    }).format(parseFloat(getAlterPrice()))}
                  </span>
                  {product.Precios.PVR.Importe > 0 ? (
                    <span className="fw-bold d-inline-block h6-listado my-0 ml-3 text-mutted text-decoration-line-through">
                      {new Intl.NumberFormat(i18n.resolvedLanguage, {
                        style: "currency",
                        currency: `${product.Precios.PVR.Divisa}`,
                      }).format(parseFloat(product.Precios.PVR.Importe))}
                    </span>
                  ) : (
                    <span className="fw-bold d-inline-block h4-listado my-0 text-dark">
                      <p></p>{" "}
                      {/* no eliminar, para que no se descuadre el cajetín */}
                    </span>
                  )}
                </div>
              ) : (
                <div className="mt-2">
                  {product.Precios.Neto.Importe > 0 ? (
                    <span
                      className="fw-bold d-inline-block h4-listado my-0 ml-3 text-dark"
                      data-testid="product-card-net-price"
                    >
                      {new Intl.NumberFormat(i18n.resolvedLanguage, {
                        style: "currency",
                        currency: `${product.Precios.Neto.Divisa}`,
                      }).format(parseFloat(product.Precios.Neto.Importe))}
                    </span>
                  ) : (
                    <span className="fw-bold d-inline-block h4-listado my-0 text-dark">
                      <p></p>
                    </span>
                  )}

                  {product.Promociones.length > 0 &&
                  tipo !== "INFOR" &&
                  product.Precios.Neto.Importe > 0 ? (
                    <span className="fw-bold d-inline-block h6-listado my-0 text-mutted p-2 text-decoration-line-through">
                      {new Intl.NumberFormat(i18n.resolvedLanguage, {
                        style: "currency",
                        currency: `${product.Precios.PVR.Divisa}`,
                      }).format(parseFloat(product.Precios.PVR.Importe))}
                    </span>
                  ) : product.Precios.PVR.Importe === 0 ? (
                    <span
                      className="fw-bold d-inline-block h6-listado my-0 text-mutted "
                      hidden
                    >
                      <br></br>
                    </span>
                  ) : (
                    <span className="fw-bold d-inline-block h6-listado my-0 text-mutted ml-3">
                      {new Intl.NumberFormat(i18n.resolvedLanguage, {
                        style: "currency",
                        currency: product.Precios.PVR.Divisa,
                      }).format(parseFloat(product.Precios.PVR.Importe))}
                    </span>
                  )}
                </div>
              )}
              <div className="float-end d-flex text-1 justify-content-center align-items-center gap-1 align-self-end ms-auto">
                {product.Precios.Cantidades !== null ? (
                  <>
                    <Image
                      ref={target}
                      onMouseOver={() => setShow(!show)}
                      onMouseOut={() => setShow(!show)}
                      id={"badge-cantidades"}
                      src={promoCantidades}
                      width={90}
                      alt={"a"}
                    />
                    <Overlay
                      target={target.current}
                      show={show}
                      placement="top"
                    >
                      {({
                        placement,
                        arrowProps,
                        show: _show,
                        popper,
                        ...props
                      }) => (
                        <div
                          className="tooltip"
                          {...props}
                          style={{
                            ...props.style,
                            bottom: "1rem",
                          }}
                        >
                          {`Si compras ${
                            product.Precios.Cantidades.Minimo
                          } te sale a 
                    ${new Intl.NumberFormat(i18n.resolvedLanguage, {
                      style: "currency",
                      currency: product.Precios.PVR.Divisa,
                    }).format(parseFloat(product.Precios.Cantidades.Importe))}`}
                        </div>
                      )}
                    </Overlay>
                  </>
                ) : (
                  <br></br>
                )}
              </div>
            </div>

            {canAccess(SECTION_PERMISSIONS.orders) && (
              <div className="footer-actions mt-3">
                <div>
                  <InputGroup>
                    <Button
                      variant="outline-secondary"
                      onClick={(event) => handleUnits(event, units - 1)}
                      disabled={units <= 0}
                    >
                      -
                    </Button>
                    <FormControl
                      aria-label={t("unidad", { count: 10 })}
                      value={units}
                      pattern="\d*"
                      onChange={(event) =>
                        handleUnits(event, parseInt(event.target.value))
                      }
                      data-testid="product-card-units"
                    />
                    <Button
                      variant="outline-secondary"
                      onClick={(event) => handleUnits(event, units + 1)}
                      disabled={
                        (product?.Caducado && units >= product.Stock) ||
                        product.Precios.Neto.Importe === 0
                      }
                    >
                      +
                    </Button>
                  </InputGroup>
                </div>
                <div>
                  <Button
                    variant="primary"
                    className="float-end"
                    onClick={handleAddToCart}
                    disabled={
                      units === 0 ||
                      units === "" ||
                      product.Precios.Neto.Importe === 0
                    }
                    data-testid="product-card-add-to-cart-button"
                  >
                    {t("anadir_al_carrito")}
                  </Button>
                </div>
              </div>
            )}
          </Card.Footer>
        </>
      )}
    </Wrapper>
  );
};
